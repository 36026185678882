import React from "react"
import { CategoryContext } from "./CategoryContext"
import utility from '../../commons/utility.js'
import ajax from '../../commons/agent.js'
import contextList from '../OrganizationInfo/contextList.js'

class CategoryProvider extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            text: "",
            contextState: {
                categoryDetails: []
            },
            categoryList: [],
            latestFetchedTime : null
        };
        contextList.CreateCategoryContext = this;
    }
    fetchUpdatedTime(){
       ajax.CategoryMaster.fetchLatestUpdatedTime().end(( err, response ) => {
            utility.handleErrors( err );
            var self = this;
            if ( !err && response ) {
               return response.body
            }
        });
    }
    fetchCategories(){
        //ajax.CategoryMaster.listCategories().end(( err, response ) => {
        ajax.MetaDataService.fetchCategory().end((err, response) =>{
            utility.handleErrors( err );
            this.setState({categoryList : []})
            var self = this;
            if ( !err && response ) {
                this.setState( { contextState: { categoryDetails: response.body } })
                response.body.map( function( items, index ) {
                    self.state.categoryList.push(
                    {
                        "key": index, "text": items.categoryName, "value": items.categoryCode
                    });
                });
            }
        });
    }
    componentDidMount() {
        this.fetchCategories();
        this.setState( { latestFetchedTime: this.fetchUpdatedTime() })
        var fetchedNewTime =  setInterval( this.fetchUpdatedTime, 10000000 );
        if(this.state.latestFetchedTime !== fetchedNewTime){
            this.fetchCategories();
        }

    }
    fetchCategoryList() {
        return contextList.CreateCategoryContext.state.categoryList;
    }
    fetchSubCategoryList( categoryId ) {
        var subCategoryOptions = [];
        var category = contextList.CreateCategoryContext.state.contextState.categoryDetails.filter( category => category.categoryId === categoryId );
        if ( category.length !== 0 ) {
            category[0].subcategory.map( function( items1, index1 ) {
                subCategoryOptions.push(
                    {
                        "key": index1, "text": items1.subcategoryName, "value": items1.subcategoryCode
                    });
            });
        }
        return subCategoryOptions;
    }
    fetchCategoryName( categoryId ) {
        var category = contextList.CreateCategoryContext.state.contextState.categoryDetails.filter( category => category.categoryCode === categoryId );
        if ( category.length !== 0 ) {
            return category[0].categoryName;
        }
        else return null;
    }
    fetchSubCategoryName( categoryId, subCategoryId ) {
        var category = contextList.CreateCategoryContext.state.contextState.categoryDetails.filter( category => category.categoryCode === categoryId );
        if ( category.length !== 0 ) {
            var subCategory = category[0].subcategory.filter( subcategory => subcategory.subcategoryCode === subCategoryId );
            if ( subCategory.length !== 0 ) {
                return subCategory[0].subcategoryName;
            }
        }
        else return null;
    }
    render() {
        return (
            <CategoryContext.Provider value={this.state.contextState}>
                {this.props.children( {
                    fetchCategoryList: this.fetchCategoryList,
                    fetchSubCategoryList: this.fetchSubCategoryList,
                    fetchCategoryName: this.fetchCategoryName,
                    fetchSubCategoryName: this.fetchSubCategoryName
                })}

            </CategoryContext.Provider>
        );
    }
}
export default CategoryProvider;
