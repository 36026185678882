import React, { Component } from 'react'
import { Grid, Icon, Label, Segment, Popup } from 'semantic-ui-react'
import utility from '../../commons/utility.js'
import moment from 'moment';


export class DcnHistoryCard extends Component {
   constructor(props) {
      super(props)

      this.state = {
         showDetails: false,
      }
   }

   render() {
      let { t } = this.props.dcnDetailPgContext.props
      let data = this.props.data
      let difference = this.props.data.difference ? JSON.parse(this.props.data.difference) : []

      return (
         <Segment basic className='py-0 m-0'>
            <div className='textNormal'>
               <span className='colPrimary textNormal cursorPointer' onClick={() => !this.props.isLast && this.setState({ showDetails: !this.state.showDetails })}>
                  <Icon className={this.state.showDetails ? 'colPrimary' : 'colGrey60'} name={'dot circle'} />
                  <span className='px-1 textBold'>
                     {this.props.isLast ?
                        <>Created At {utility.formatDate(this.props.data.createdAt)}</>
                        :
                        <>Updated At {utility.formatDate(this.props.data.updatedAt)}</>
                     }
                  </span>
                  {!this.props.isLast && <>
                     {/* <span className='colGrey60 pr-5px'>{difference.length} change(s)</span> */}
                     <Icon className='colGrey80' name={this.state.showDetails ? 'chevron down' : 'chevron left'} />
                  </>}
               </span>
               <Popup position='bottom center' trigger={<span className='pl-1 textSmall customLink'>DCN</span>} on='click' pinned flowing>
                  <div>
                     <div className='textNormal textBold mb-1'>{this.props.data.dcnFileName}</div>
                     <span className='customLink' onClick={() => this.props.dcnDetailPgContext.setState({ pdfPreviewSelected: { fileName: data.dcnFileName, bpid: data.bpid } })}><Icon name='eye' ></Icon>Preview</span>
                     <span className='customLink px-1' onClick={() => this.props.dcnDetailPgContext.downloadFile(data.dcnId, data.dcnFileName)}><Icon name='download' />Download</span>
                  </div>
               </Popup >
               <Popup position='bottom center' trigger={<span className='pl-1 textSmall customLink'>Service of Notice</span>} on='click' pinned flowing>
                  <div>
                     <div className='textNormal textBold mb-1'>{this.props.data.sonFileName}</div>
                     <span className='customLink' onClick={() => this.props.dcnDetailPgContext.setState({ pdfPreviewSelected: { fileName: data.sonFileName, bpid: data.bpid } })}><Icon name='eye'></Icon>Preview</span>
                     <span className='customLink px-1' onClick={() => this.props.dcnDetailPgContext.downloadFile(data.dcnId, data.sonFileName)}><Icon name='download' />Download</span>
                  </div>
               </Popup >
            </div >
            <div className='' style={{ padding: '14px 28px', marginLeft: '8px', borderLeft: `${!this.props.isLast && '2px solid hsl(0deg 0% 84%)'}` }}>
               {this.state.showDetails && <Grid columns={2} className='ml-1'>
                  {difference.length > 0 && difference.map((item, key) => {
                     return <React.Fragment key={key}>
                        <Grid.Column computer={4} tablet={4} mobile={16} className={`py-0`} >
                           <span className='textBold textNormal'>{t(item.property)}</span>
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={3} mobile={7} className={`py-0`} >
                           {(() => {
                              if (item.property === 'ppDob' || item.property === 'createdAt' || item.property === 'updatedAt') {
                                 return (item.left ? utility.formatDate(item.left) : ' ')
                              }                  
                              else if (item.property === 'dcnEffectiveDate' || item.property === 'effectiveDate') {
                                 return (item.left ? moment.utc(item.left).local().format("DD-MM-YYYY") : ' ')
                              }
                              else if (item.property === 'bpid') {
                                 return (this.props.fetchOrganizationName(item.left))
                              } else {
                                 return (item.left)
                              }
                           })()}
                           {/* {item.left} */}
                        </Grid.Column>
                        <Grid.Column computer={1} tablet={1} mobile={2} className={`py-0`} textAlign='center'>
                           <Icon name='angle double right' className='colGrey80' />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={7} className={`py-0`} >
                           {(() => {
                              if (item.property === 'ppDob' || item.property === 'createdAt' || item.property === 'updatedAt') {
                                 return (item.right ? utility.formatDate(item.right) : ' ')
                              }
                              else if (item.property === 'dcnEffectiveDate' || item.property === 'effectiveDate') {
                                 return (item.right ? moment.utc(item.right).local().format("DD-MM-YYYY") : ' ')
                              }
                              else if (item.property === 'bpid') {
                                 return (this.props.fetchOrganizationName(item.right))
                              } else {
                                 return (item.right)
                              }
                           })()}
                        </Grid.Column>
                     </React.Fragment>
                  })}
               </Grid>}
            </div>
         </Segment >
      )
   }
}

export default DcnHistoryCard

