import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import CryptoJS  from "crypto-js";
import {Input, Grid, SegmentGroup, Segment, Button, Form} from 'semantic-ui-react'

export class Encrypt extends Component {
    constructor(props){
        super(props);
        this.state={inputStr:"", encodedStr:"", orginalText:""};
    }

    handleTextChange(){
        let encrypted = CryptoJS.AES.encrypt(this.state.inputStr, "Secret").toString();

        


        var inbytes  = CryptoJS.AES.decrypt(encrypted, 'Secret');
        var plaintext = inbytes.toString(CryptoJS.enc.Utf8);


        this.setState({encodedStr : encrypted, orginalText: plaintext})

    }

    render(){
        return(<>
            <SegmentGroup>
                <Segment>
                    <Grid>
                    <Grid.Column computer={12} className='pb-0'>
                        <Form>
                            <Form.Input name='inputStr' value={this.state.inputStr} onChange={(e) => this.setState({inputStr:e.target.value})} fluid label="Free Text Input" placeholder="Input String to Encode" required />
                        </Form>
                        <Button className='customBtn1Primary mr-0' onClick={() => this.handleTextChange()}>Encrypt</Button><br/>
                        <label>{this.state.encodedStr}</label><br/>
                        <label>{this.state.orginalText}</label>
                    </Grid.Column>
                    </Grid>
                </Segment>
            </SegmentGroup>
        </>)
    }
}
export default (withRouter(Encrypt))