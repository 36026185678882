import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom"
import { Modal, Table, Grid, Header, Segment, Divider, Form, Button, Accordion, Icon, Checkbox, Input } from 'semantic-ui-react'
import { withTranslation } from "react-i18next"
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DisplayMessage from '../../commons/displayMessage.jsx'


export class ApproveRejectModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            messageType: '', messageHeader: '', message: [],

            orgAproveRemark: '',
            orgRejectRemark: '',
            userAproveRemark: '',
            userRejectRemark: '',

            isSubmittingOrgApprove: false,
            isSubmittingOrgReject: false,
            isSubmittingUserApprove: false,
            isSubmittingUserReject: false,
        }
    }

    handleTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    orgApproveSubmit = () => {
        let errArr = []
        let Vo = {
            organizationId: this.props.OrgDetailsData.organizationId,
            remark: this.state.orgAproveRemark
        }

        if (!Vo.remark) errArr.push('Remark is required')

        if (errArr.length > 0) {
            utility.loadDisplayMessage(this, "error", "Error", errArr)
        }
        else {
            let context = this.props.OrganizationDetailsModalContext
            this.setState({ isSubmittingOrgApprove: true })
            ajax.OrganizationMaster.approveOrganization(Vo).end((err, res) => {
                this.setState({ isSubmittingOrgApprove: false })
                if (!err && res) {
                    this.props.isCloseOrgAproveModal()
                    context.organizationDetailFetch(context.props.organizationId),
                        context.props.organizationRequestsContext.pageReload()

                }
                else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
            }
            )
        }
    }

    orgRejectSubmit = () => {
        let errArr = []
        let Vo = {
            organizationId: this.props.OrgDetailsData.organizationId,
            remark: this.state.orgRejectRemark
        }

        if (!Vo.remark) errArr.push('Remark is required')

        if (errArr.length > 0) {
            utility.loadDisplayMessage(this, "error", "Error", errArr)
        }
        else {
            let context = this.props.OrganizationDetailsModalContext
            this.setState({ isSubmittingOrgReject: true })
            ajax.OrganizationMaster.rejectOrganization(Vo).end((err, res) => {
                this.setState({ isSubmittingOrgReject: false })
                if (!err && res) {
                    this.props.isCloseOrgRejectModal()
                    context.organizationDetailFetch(context.props.organizationId),
                        context.props.organizationRequestsContext.pageReload()
                }
                else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
            }
            )
        }
    }
    userApproveSubmit = (userId) => {
        let errArr = []
        let Vo = {
            userId: userId,
            remark: this.state.userAproveRemark
        }

        if (!Vo.remark) errArr.push('Remark is required')

        if (errArr.length > 0) {
            utility.loadDisplayMessage(this, "error", "Error", errArr)
        }
        else {
            let context = this.props.OrganizationDetailsModalContext
            this.setState({ isSubmittingUserApprove: true })
            ajax.OrganizationMaster.approveUser(Vo).end((err, res) => {
                this.setState({ isSubmittingUserApprove: false })
                if (!err && res) {
                    this.props.isCloseUserAproveModal()
                    context.organizationDetailFetch(context.props.organizationId)
                }
                else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
            })
        }
    }

    userRejectSubmit = (userId) => {
        let errArr = []
        let Vo = {
            userId: userId,
            remark: this.state.userRejectRemark
        }

        if (!Vo.remark) errArr.push('Remark is required')

        if (errArr.length > 0) {
            utility.loadDisplayMessage(this, "error", "Error", errArr)
        }
        else {
            let context = this.props.OrganizationDetailsModalContext
            this.setState({ isSubmittingUserReject: true })
            ajax.OrganizationMaster.rejectUser(Vo).end((err, res) => {
                this.setState({ isSubmittingUserReject: false })
                if (!err && res) {
                    this.props.isCloseUserRejectModal()
                    context.organizationDetailFetch(context.props.organizationId)
                }
                else { utility.loadDisplayMessage(this, "error", "Something went wrong.", []) }
            })
        }

    }

    render() {
        const { t } = this.props
        return (
            <>
                <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
                <Modal
                    open={this.props.isOpenOrgAproveModal}
                    centered={false}
                    onClose={() => this.props.isCloseOrgAproveModal()}
                    dimmer='inverted'
                    size='tiny'
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.TextArea name='orgAproveRemark' value={this.state.orgAproveRemark} onChange={(e) => this.handleTextChange(e)} label='Remark' placeholder='Remark' required />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='customBtn1Secondary' onClick={() => this.props.isCloseOrgAproveModal()}> {t('close')}</Button>
                        <Button className='customBtn1Primary' loading={this.state.isSubmittingOrgApprove} onClick={() => this.orgApproveSubmit()}> {t('confirm')}</Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    open={this.props.isOpenOrgRejectModal}
                    centered={false}
                    onClose={() => this.props.isCloseOrgRejectModal()}
                    dimmer='inverted'
                    size='tiny'
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.TextArea name='orgRejectRemark' value={this.state.orgRejectRemark} onChange={(e) => this.handleTextChange(e)} label='Remark' placeholder='Remark' required />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='customBtn1Secondary' onClick={() => this.props.isCloseOrgRejectModal()}> {t('close')}</Button>
                        <Button className='customBtn1Primary' loading={this.state.isSubmittingOrgReject} onClick={() => this.orgRejectSubmit()} > {t('confirm')}</Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    open={this.props.isOpenUserAproveModal}
                    centered={false}
                    onClose={() => this.props.isCloseUserAproveModal()}
                    dimmer='inverted'
                    size='tiny'
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.TextArea name='userAproveRemark' value={this.state.userAproveRemark} onChange={(e) => this.handleTextChange(e)} label='Remark' placeholder='Remark' required />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='customBtn1Secondary' onClick={() => this.props.isCloseUserAproveModal()}> {t('close')}</Button>
                        <Button className='customBtn1Primary' loading={this.state.isSubmittingUserApprove} onClick={() => this.userApproveSubmit(this.props.isOpenUserAproveModal)}> {t('confirm')}</Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    open={this.props.isOpenUserRejectModal}
                    centered={false}
                    onClose={() => this.props.isCloseUserRejectModal()}
                    dimmer='inverted'
                    size='tiny'
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.TextArea name='userRejectRemark' value={this.state.userRejectRemark} onChange={(e) => this.handleTextChange(e)} label='Remark' placeholder='Remark' required />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='customBtn1Secondary' onClick={() => this.props.isCloseUserRejectModal()}> {t('close')}</Button>
                        <Button className='customBtn1Primary' loading={this.state.isSubmittingUserReject} onClick={() => this.userRejectSubmit(this.props.isOpenUserRejectModal)} > {t('confirm')}</Button>
                    </Modal.Actions>
                </Modal>
            </>

        )
    }
}

export default withTranslation()(withRouter(ApproveRejectModal))

