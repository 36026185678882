import axios from "axios";
import {decodeIdToken, initAuthenticatedSession} from "./session";
import {CONFIG} from "../config";
import ajax from '../../../commons/agent.js'
import CryptoJS  from "crypto-js";

/**
 * Sends an authorization request.
 */
export const sendAuthorizationRequest = () => {
    ajax.UserManagementService.uiconfig().end((err, res) => {
        if (!err && res) {
            //console.log(res.body)
            let AUTHORIZE_ENDPOINT = res.body.identityServerUrl + "oauth2/authorize";
            let RESPONSE_TYPE = "code";
            let SCOPE = "openid";
            let REDIRECT_URI = res.body.redirectURI;
            let ciphertext = res.body.clientKey;
            let bytes  = CryptoJS.AES.decrypt(ciphertext, 'Secret');
            let CLIENT_ID = bytes.toString(CryptoJS.enc.Utf8);
            //console.log("AUTHORIZE_ENDPOINT :"+AUTHORIZE_ENDPOINT)
            //console.log("REDIRECT_URI :"+REDIRECT_URI)
            //console.log("CLIENT_ID :"+CLIENT_ID)
         
            let authorizeRequest = `${ AUTHORIZE_ENDPOINT }?response_type=${ RESPONSE_TYPE }&scope=${ SCOPE }&redirect_uri=${ REDIRECT_URI }&client_id=${ CLIENT_ID }`;
            window.location.href = authorizeRequest;
        }
    })

};

/**
 * Sends a token request.
 *
 * @param code Authorization code
 * @return {Promise<AxiosResponse<T> | never>}
 */
export const sendTokenRequest = (body, TOKEN_ENDPOINT, CLIENT_URL) => {
    return axios.post(`${ TOKEN_ENDPOINT }`, body.join("&"), getTokenRequestHeaders(CLIENT_URL))
        .then(response => {
            //console.log(response);
            if (response.status !== 200) {
                return Promise.reject(new Error("Invalid status code received in the token response: "
                    + response.status));
            }

            // Store the response in the session storage
            initAuthenticatedSession(response.data);

            return [response.data, decodeIdToken(response.data.id_token)];

        }).catch((error) => {
            return Promise.reject(error);
        })        

};


/**
 * Helper to set request headers.
 *
 * @return {{headers: {Accept: string, "Access-Control-Allow-Origin": string, "Content-Type": string}}}
 */
const getTokenRequestHeaders = (CLIENT_URL) => {
    return {
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": `${ CLIENT_URL }`,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
};
