import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import { Button, Input, Icon, Loader, Grid, Segment, Label } from 'semantic-ui-react';
import { Table } from 'antd';
import moment from 'moment'
import PageHeader from '../PageHeader.jsx';
import { withTranslation } from 'react-i18next';
import OrganizationDetailsModal from './OrganizationDetailsModal.jsx';


class Worklist extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         data: [], dataLength: 0, errorResponse: [],
         filteredInfo: null, sortedInfo: null,
         searchText: '', searchedColumn: '',
         currentDataCount: 0,

         catsubcatFilter: null,
         categoryOptions: null,

         isOpenOrgDetailsModal: false,
         selectedorganizationId: '',
      };
   }

   componentDidMount() {
      this.pageReload();
   }
   pageReload() {
      const self = this

      this.setState({ isFetching: true })
      ajax.OrganizationMaster.listOrganizations().end((err, response) => {
         this.setState({ isFetching: false })
         let temp = utility.handleErrors(err);
         if (!err && response) {
            self.setState({ data: response.body, dataLength: response.body.length },
               () => {
                  this.setState(prevState => {
                     prevState.data.map(item => {
                        // item.sellerOrganizationName = this.props.fetchOrganizationName(item.sellerBpid)
                        // item.pendingWith = item.pendingWithBpid && this.props.fetchOrganizationName(item.pendingWithBpid)
                        item.createdAt = item.createdAt && utility.formatDate(item.createdAt)
                        item.status = item.status || ''
                        // item.lastUpdatedAt = item.lastUpdatedAt && utility.formatDate(item.lastUpdatedAt)

                     })
                  })
               });
         }
         else {
            this.setState({ errorResponse: temp });
         }
      })


   }



   //search start
   getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               size='small'
               className='customInput'
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Search`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ marginBottom: 8, display: 'block' }}
            />
            <div >
               <Button icon='redo' content='Reset' size="small" compact className='customBtn1Secondary' style={{ width: '48%' }} onClick={() => this.handleReset(clearFilters)} />
               <Button icon='search' content='Search' size="small" compact className='customBtn1Primary' style={{ width: '50%', marginRight: '0' }} onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} />
            </div>
         </div>
      ),
      filterIcon: filtered => <Icon name='search' className={filtered ? 'colPrimary' : ''} style={{ padding: '15px 0px' }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: text => text,
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };

   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };
   //search end 


   handleChange = (pagination, filters, sorter, currentDataSource) => {
      this.setState({
         filteredInfo: filters,
         sortedInfo: sorter,
      });
   };

   clearAll = () => {
      this.setState({
         filteredInfo: null,
         sortedInfo: null,
      });
   };

   //End of Table Function
   handleRemarkChange = (organizationId, value) => {
      let data = [...this.state.data]
      data.map(item => (item.organizationId === organizationId) && (item.remark = value))
      this.setState({ data })

   }

   render() {
      const { t } = this.props
      let { sortedInfo, filteredInfo, pipelineVo } = this.state;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const cellStyle = { color: 'var(--grey20)', padding: '6px 8px', fontSize: '.98em' }

      const filename = 'DCNs_' + Date.now(),
         fields = {
            "dcnReferenceNo": "DCNREFERENCENO",
            "dogOwnerName": "DOG OWNER NAME",
            "dogOwnerAddress": "DOG OWNER ADDRESS",
            "dogName": "DOG NAME",
            "dogBreed": "DOG BREED",
            "createdAt": "CREATED AT",
            "dcnStatus": "DCN STATUS",
            // "buyerOrganizationName2": "BUYER",
         },
         style = {
            padding: "0px 12px", fontFamily: 'Roboto', fontSize: '0.92em', border: 'none', height: '32px', lineHeight: '30px'
         },
         text = "Download"

      const columns = [
         {
            title: 'BPID', dataIndex: 'organizationBpid', key: 'organizationBpid',
            ...this.getColumnSearchProps('organizationBpid'),
            width: '10%',
            // fixed: 'left',
            ellipsis: true,
            render: (organizationBpid, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <><span className='textBold'>{organizationBpid}</span></>
               }
            }
         },
         {
            title: 'Organisation Name', dataIndex: 'organizationName', key: 'organizationName',
            ...this.getColumnSearchProps('organizationName'),
            width: '16%',
            // fixed: 'left',
            ellipsis: true,
            render: (organizationName, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <><Link className='customColPrimary' onClick={() => this.setState({ selectedorganizationId: record.organizationId, isOpenOrgDetailsModal: true })} >{organizationName}</Link></>
               }
            }
         },
         {
            title: 'Requested On', dataIndex: 'createdAt', key: 'createdAt',
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
            width: '12%',
            // fixed: 'left',
            ellipsis: true,
            render: (createdAt, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     <span className=''> {createdAt}</span>
                  </>
               }
            }
         },
         {
            title: 'Status', dataIndex: 'status', key: 'status',
            filters: [{ text: 'Approved', value: 'Approved' }, { text: 'Rejected', value: 'Rejected' }, { text: 'Pending', value: 'Pending' }],
            filterMultiple: true,
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            width: '10%',
            // fixed: 'left',
            ellipsis: true,
            render: (status, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {status === 'Approved' && <Label empty size='tiny' color='green' circular></Label>}
                     {status === 'Rejected' && <Label empty size='tiny' color='red' circular></Label>}
                     {status === 'Pending' && <Label empty size='tiny' color='orange' circular></Label>}
                     <span className='textBold'> {status}</span>
                  </>
               }
            }
         },
      ]


      return (
         <>
            <PageHeader />
            <Segment basic className='py-0' loading={this.state.isFetching}>
               <div style={{ padding: '0px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{ display: 'contents', fontSize: '1.2em', fontWeight: '400' }}>{this.state.currentDataCount} {this.state.currentDataCount !== this.state.dataLength && `of ${this.state.dataLength}`} {t('results')} </span>
                  <div>
                     <span style={{ marginRight: '30px' }}>
                        {/* <span style={fLegend}><Icon color='blue' name='flask' /> Evaluation Required </span> */}
                     </span>

                     {/* {this.state.data.length > 0 && <JsonToCsv data={this.state.data} filename={filename} fields={fields} style={style} text={text} />} */}
                     {/* <Button icon='download' content='Download' className='mr-0 customBtn1Secondary mr-1' compact size='small' onClick={() => this.downloadAllDcns()} /> */}
                     <Button icon='redo' content={t('reload')} className='customBtn1Secondary' compact size='small' onClick={() => this.pageReload()} />
                     <Button icon='undo' content={t('resetFiltersAndSorters')} className='mr-0 customBtn1Secondary' compact size='small' onClick={this.clearAll} />
                  </div>
               </div>

               <Table
                  size="middle"
                  columns={columns}
                  rowKey={obj => obj.dcnId}
                  dataSource={this.state.data}
                  onChange={this.handleChange}
                  loading={{
                     indicator: <div><Loader active size='small' /></div>,
                     spinning: this.state.isFetching
                  }}
                  locale={{ emptyText: 'No Data' }}
                  className='border-1 bgWhite scrollableTable'
                  // scroll={{ x: 'calc(600px + 50%)' }}
                  scroll={{ x: 1080 }}
                  rowClassName={(record, index) => index % 2 === 0 ? 'bgWhite' : 'bgWhite'}
                  pagination={{ showTotal: (total, range) => this.state.currentDataCount !== total && this.setState({ currentDataCount: total }), defaultPageSize: 10, showSizeChanger: true, size: 'default', pageSizeOptions: ['10', '20', '40', '50', '100', '500',] }}
               />

               <OrganizationDetailsModal
                  organizationRequestsContext={this} organizationId={this.state.selectedorganizationId}
                  isOpenOrgDetailsModal={this.state.isOpenOrgDetailsModal}
                  isCloseOrgDetailsModal={() => this.setState({ isOpenOrgDetailsModal: false })}
               />
            </Segment>
         </>
      );

   }

}

export default withTranslation()(withRouter(Worklist));
