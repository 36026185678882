import React from "react";
import { OrganizationContext } from "./OrganizationContext.js";
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import contextList from './contextList.js';
class OrganizationProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            contextState: {
                organizationList: [],
                organization: null,
                orgFetched: false,
            },
            latestFetchedTime: null
        };
        contextList.CreateOrganizationContext = this;
    }
    fetchUpdatedTime() {
        ajax.OrganizationMaster.fetchLatestUpdateTime().end((err, response) => {
            utility.handleErrors(err);
            var self = this;
            if (!err && response) {
                return response.body
            }
        });
    }
    fetchOrganizations() {
        this.setState({ contextState: { organizationList: [] } })
        ajax.OrganizationMaster.listOrganizations().end((err, response) => {
            // console.log(response.body)
            utility.handleErrors(err);
            var self = this;
            if (!err && response) {
                this.setState({ contextState: { organizationList: response.body, orgFetched: true } })
            }
            else {
                this.setState({ contextState: { organizationList: [], orgFetched: true } })
            }
        });
    }
    componentDidMount() {
        this.fetchOrganizations();
        this.setState({ latestFetchedTime: this.fetchUpdatedTime() })
        var fetchedNewTime = setInterval(this.fetchUpdatedTime, 10000000);
        if (this.state.latestFetchedTime !== fetchedNewTime) {
            this.fetchOrganizations();
        }
    }
    reloadOrgContext = () => {
        this.fetchOrganizations();
    }
    fetchOrganizationList() {
        return contextList.CreateOrganizationContext.state.contextState.organizationList;
    }
    fetchOrganizationName(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0].organizationName
        }
    }
    fetchOrganizationProfile(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0].organizationProfile
        }
    }
    fetchOrganizationWebsite(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0].website
        }
    }
    fetchOrganizationEmail(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0].emailId
        }
    }
    fetchOrganizationCategory(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0].organizationCategory
        }
    }
    fetchOrganization(organizationBpid) {
        var organization = contextList.CreateOrganizationContext.state.contextState.organizationList.filter(organization => organization.organizationBpid === organizationBpid);
        if (organization.length !== 0) {
            return organization[0]
        }
    }

    render() {
        return (
            <OrganizationContext.Provider value={this.state.contextState}>
                {this.props.children({
                    fetchOrganizationList: this.fetchOrganizationList,
                    fetchOrganizationName: this.fetchOrganizationName,
                    fetchOrganizationProfile: this.fetchOrganizationProfile,
                    fetchOrganizationWebsite: this.fetchOrganizationWebsite,
                    fetchOrganizationEmail: this.fetchOrganizationEmail,
                    fetchOrganizationCategory: this.fetchOrganizationCategory,
                    fetchOrganization: this.fetchOrganization,
                    reloadOrgContext:this.reloadOrgContext
                })}

            </OrganizationContext.Provider>
        );
    }
}
export default OrganizationProvider;
