export const CONFIG = {
    "TOKEN_ENDPOINT": "https://10.10.171.11:9445/oauth2/token",
    "AUTHORIZE_ENDPOINT": "https://10.10.171.11:9445/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "SCOPE": "openid",
    "REDIRECT_URI": "https://10.10.171.11/login",
    "CLIENT_ID": "fAtuT9IKiDYvNZXxXobOowVs2fca",
    "CLIENT_SECRET": "SWGDvalfQfkxH4Yu1grDN1dJgSUa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "https://10.10.171.11",
    "LOGOUT_URL": "https://10.10.171.11:9445/oidc/logout",
    "COOKIE_PATH": "/"
}