import React, { Component } from 'react'
import { Grid, Segment, Header, Icon, Image, Label } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom";
import utility from '../../commons/utility.js'
import { withTranslation } from "react-i18next";
import UserDetails from '../../userInfo/UserDetails.js';

export class OrganizationCard extends Component {

    render() {
        const { t, data } = this.props
        return (
            <Grid.Column stretched className='py-5px' computer={4} tablet={8} mobile={16}>
                <Segment as={Link} to={`/dashboard/organisations/${data.organizationBpid}`} className='fCard'>
                    <Header as='h4' className='mb-5px'>
                        <Icon name='building' className='colPrimary' />
                        {/* <Image src="/images/org.png" /> */}
                        <Header.Content>
                            <span className='textSmall' title={data.organizationName}>{utility.elipses(data.organizationName, 28)}</span>
                            <Header.Subheader className='textSmaller'>
                                {data.organizationCategory}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Grid>
                        <Grid.Column textAlign='right' className='pb-5px px-0'>
                            {data.organizationBpid === UserDetails.bpId &&
                                <Label size='small' horizontal className='bgPrimary colWhite textThin'>
                                    My Organisation
                                </Label>
                            }
                        </Grid.Column>
                    </Grid>
                    {/* <p className='my-5px mb-0 colGrey20 textSmaller breakLongText'>{utility.elipses(data.organizationProfile, 140)}</p> */}
                </Segment>
            </Grid.Column>
        )
    }
}

export default withTranslation()(OrganizationCard)
