import React from "react";
import { Grid, Breadcrumb, Icon } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import utility from '../commons/utility.js'
import { withTranslation } from "react-i18next";

function PageHeader(props) {
    const { t } = props
    const path = props.customPath || props.location.pathname
    const pathnames = path.split('/').filter((x) => x)
    const currentPage = pathnames[pathnames.length - 1]
    return (
        <Grid columns={1} className='px-1 mb-1' stretched={false}>
            <Grid.Column className='py-5px pb-0' verticalAlign='middle'>
                <Icon name='arrow left' link
                    disabled={props.history.length === 0}
                    onClick={() => props.history.goBack()}
                />
                {/* <Icon name='arrow right' circular link
                    disabled={props.history.length === 0}
                    onClick={() => props.history.goForward()}
                /> */}
                <span className="pageHeader"> {t(currentPage)}</span>
            </Grid.Column>
            {pathnames && pathnames.length > 1 && <Grid.Column className='py-0' style={{ lineHeight: 'normal' }} >
                <Breadcrumb style={{ position: 'relative', top: '-6px', left: '26px' }}>
                    {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`

                        return !last ?
                            <React.Fragment key={index}>
                                <Breadcrumb.Section as={Link} to={to}>{t(value)}</Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right angle' />
                            </React.Fragment>
                            :
                            <React.Fragment key={index}>
                                <Breadcrumb.Section>
                                    {t(value)}
                                </Breadcrumb.Section>
                            </React.Fragment>;
                    })}
                </Breadcrumb>
            </Grid.Column>}
        </Grid>
    )
}

export default withTranslation()(withRouter(PageHeader))
