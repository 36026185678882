import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom"
import { Modal, Table, Grid, Header, Segment, Divider, Form, Button, Accordion, Icon, Checkbox, Label } from 'semantic-ui-react'
import { withTranslation } from "react-i18next"
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DisplayMessage from '../../commons/displayMessage.jsx'
import ApproveRejectModal from './ApproveRejectModal.jsx'


export class OrganizationDetailsModal extends Component {
   constructor(props) {
      super(props)

      this.state = {
         messageType: '', messageHeader: '', message: [],

         OrgDetailsData: null,
         isFatchingOrgDetails: false,
         isOpenOrgAproveModal: false,
         isOpenOrgRejectModal: false,
         isOpenUserAproveModal: false,
         isOpenUserRejectModal: false,
      }
   }

   // componentDidMount() {
   //     this.onLoad()
   // }


   componentDidUpdate(prevProps, prevState) {
      if (prevProps.organizationId !== this.props.organizationId) {
         this.organizationDetailFetch(this.props.organizationId)
      }
   }


   organizationDetailFetch = (organizationId) => {
      this.setState({ isOpenOrgDetailsModal: true, isFatchingOrgDetails: true, OrgDetailsData: null })
      ajax.OrganizationMaster.fetchOrganizationByOrgId(organizationId).end((err, res) => {
         this.setState({ isFatchingOrgDetails: false })
         if (!err && res) {
            this.setState({ OrgDetailsData: res.body })
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })
   }


   render() {
      const { t, organizationRequestsContext } = this.props
      const { OrgDetailsData } = this.state
      return (
         <>
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Modal
               open={this.props.isOpenOrgDetailsModal}
               centered={false}
               onClose={() => this.props.isCloseOrgDetailsModal()}
               dimmer='inverted'
               size='large'
            >
               <Modal.Header>
                  <Grid columns='equal'>
                     <Grid.Column className='py-5px' verticalAlign='middle' >{t('organizationDetails')}</Grid.Column>
                     {OrgDetailsData && OrgDetailsData.status === 'Pending' &&
                        <Grid.Column className='py-5px' verticalAlign='middle' textAlign='right' >
                           <Button.Group size='small' >
                              <Button negative onClick={() => this.setState({ isOpenOrgRejectModal: true })}>Reject</Button>
                              <Button.Or />
                              <Button positive onClick={() => this.setState({ isOpenOrgAproveModal: true })}>Approve</Button>
                           </Button.Group>
                        </Grid.Column>
                     }
                  </Grid>
               </Modal.Header>
               <Modal.Content className='pt-0'>
                  <Grid as={Segment} basic loading={this.state.isFatchingOrgDetails} columns={3}>
                     {OrgDetailsData && <>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('requestId')}</div>
                           <div className='contentMedium'>{OrgDetailsData.organizationId}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('organizationBpid')}</div>
                           <div className='contentMedium'>{OrgDetailsData.organizationBpid}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('status')}</div>
                           {OrgDetailsData.status === 'Approved' && <Label empty size='tiny' color='green' circular></Label>}
                           {OrgDetailsData.status === 'Rejected' && <Label empty size='tiny' color='red' circular></Label>}
                           {OrgDetailsData.status === 'Pending' && <Label empty size='tiny' color='orange' circular></Label>}
                           <span className='contentMedium'> {OrgDetailsData.status}</span>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('organizationCategory')}</div>
                           <div className='contentMedium'>{OrgDetailsData.organizationCategory}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('organizationName')}</div>
                           <div className='contentMedium'>{OrgDetailsData.organizationName}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('website')}</div>
                           <div className='contentMedium' title={OrgDetailsData.website}>{utility.elipses(OrgDetailsData.website, 34)}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('address')}</div>
                           <div className='contentMedium'>{OrgDetailsData.addressLine}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('city')}</div>
                           <div className='contentMedium'>{OrgDetailsData.city}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('state')}</div>
                           <div className='contentMedium'>{OrgDetailsData.stateProvinceRegion}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('country')}</div>
                           <div className='contentMedium'>{OrgDetailsData.country}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('postalCode')}</div>
                           <div className='contentMedium'>{OrgDetailsData.zipCode}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('emailId')}</div>
                           <div className='contentMedium'>{OrgDetailsData.emailId}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('contactNo')}</div>
                           <div className='contentMedium'>{OrgDetailsData.contactNumber}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('requestDate')}</div>
                           <div className='contentMedium'>{utility.formatDate(OrgDetailsData.createdAt)}</div>
                        </Grid.Column>
                        <Grid.Column className='py-0'>
                           <div className='contentLabel'>{t('remark')}</div>
                           <div className='contentMedium'>{OrgDetailsData.remark}</div>
                        </Grid.Column>
                     </>}
                  </Grid>

                  <div className='colPrimary textNormal textBold mb-1'>Users</div>
                  {OrgDetailsData && OrgDetailsData.organizationUsersVo &&
                     <Grid>
                        {OrgDetailsData.organizationUsersVo.map(item =>
                           <Grid.Column className='py-5px' computer={8} tablet={8} mobile={16}>
                              <Segment className='fCard' style={{ padding: '10px' }}>
                                 <Grid columns='equal'>
                                    <Grid.Column width={2} textAlign='center' className='pr-0'>
                                       <Icon className='colPrimary' name='user' size='big' />
                                    </Grid.Column>
                                    <Grid.Column>
                                       <div className='colBlack textBold textSmall'>{item.firstName} {item.lastName}</div>
                                       <p className='colGrey40 my-0 mb-0 textSmaller'>{utility.timeAgo(item.createdAt)}</p>
                                    </Grid.Column>
                                 </Grid>
                                 <Grid columns='equal'>
                                    <Grid.Column className='py-0'>
                                       <div className='textSmall colGrey40'>
                                          <span>{t('userId')} : </span>
                                          <span >{item.userId}</span>
                                       </div>
                                       <div className='textSmall colGrey40'>
                                          <span>{t('emailId')} : </span>
                                          <span >{item.email}</span>
                                       </div>
                                       <div className='textSmall colGrey40'>
                                          <span>{t('contactNumber')} : </span>
                                          <span >{item.contactNumber}</span>
                                       </div>
                                    </Grid.Column>
                                 </Grid>
                                 <Grid columns='equal'>
                                    <Grid.Column className='pt-0' verticalAlign='middle' textAlign='left'>
                                       {item.status === 'Approved' && <Label empty size='tiny' color='green' circular></Label>}
                                       {item.status === 'Rejected' && <Label empty size='tiny' color='red' circular></Label>}
                                       {item.status === 'Pending' && <Label empty size='tiny' color='orange' circular></Label>}
                                       <span > {item.status}</span>
                                    </Grid.Column>
                                    {item.status === 'Pending' && OrgDetailsData && OrgDetailsData.status === 'Approved' &&
                                       <Grid.Column className='pt-0' textAlign='right'>
                                          <Button.Group size='tiny' >
                                             <Button negative onClick={() => this.setState({ isOpenUserRejectModal: item.userId })}>Reject</Button>
                                             <Button.Or />
                                             <Button positive onClick={() => this.setState({ isOpenUserAproveModal: item.userId })}>Approve</Button>
                                          </Button.Group>
                                       </Grid.Column>
                                    }
                                 </Grid>

                              </Segment>
                           </Grid.Column>
                        )}
                     </Grid>
                  }
               </Modal.Content>
               <Modal.Actions>
                  <Button className='customBtn1Secondary' onClick={() => this.props.isCloseOrgDetailsModal()} > {t('close')}</Button>
               </Modal.Actions>
            </Modal>
            <ApproveRejectModal
               OrgDetailsData={OrgDetailsData}
               OrganizationDetailsModalContext={this}

               isOpenOrgAproveModal={this.state.isOpenOrgAproveModal}
               isCloseOrgAproveModal={() => this.setState({ isOpenOrgAproveModal: false })}

               isOpenOrgRejectModal={this.state.isOpenOrgRejectModal}
               isCloseOrgRejectModal={() => this.setState({ isOpenOrgRejectModal: false })}

               isOpenUserAproveModal={this.state.isOpenUserAproveModal}
               isCloseUserAproveModal={() => this.setState({ isOpenUserAproveModal: false })}

               isOpenUserRejectModal={this.state.isOpenUserRejectModal}
               isCloseUserRejectModal={() => this.setState({ isOpenUserRejectModal: false })}
            />
         </>

      )
   }
}

export default withTranslation()(withRouter(OrganizationDetailsModal))

