import React from "react";
import { sendAuthorizationRequest, sendTokenRequest } from "./actions/sign-in";
import ReactJson from 'react-json-view'
import { dispatchLogout } from "./actions/sign-out";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "./actions/session";
import UserDetails from '../../userInfo/UserDetails.js';
import { Grid, Loader, Segment } from "semantic-ui-react";
import ajax from '../../commons/agent.js';
import CryptoJS  from "crypto-js";

class HomeComponent extends React.Component {
    state = {
        idToken: {},
        tokenResponse: {},
        isLoggedIn: false,

    };

    componentDidMount() {
        // See if there is a valid session.
        //console.log(this.props)
        // console.log("isValidSession() returns - " + isValidSession())
        if (isValidSession()) {

            const session = getAllSessionParameters();
            //console.log(session)
            if (session != null) {
                //forward to user page.
                // window.location.href = window.location.protocol + '//' + window.location.host + "/dashboard";
                window.location.href = window.location.protocol + '//' + window.location.host ;
            }
            const _tokenResponse = {
                access_token: session.ACCESS_TOKEN,
                refresh_token: session.REFRESH_TOKEN,
                scope: session.SCOPE,
                id_token: session.ID_TOKEN,
                token_type: session.TOKEN_TYPE,
                expires_in: parseInt(session.EXPIRES_IN),
            };
            this.setState({
                tokenResponse: _tokenResponse,
                idToken: decodeIdToken(session.ID_TOKEN),
                isLoggedIn: true
            });
            return;
        }

        // Reads the URL and retrieves the `code` param.
        const code = new URL(window.location.href).searchParams.get("code");
        // If a authorization code exists, sends a token request.
        if (code) {
            var promise = new Promise((resolve, reject) => {
                ajax.UserManagementService.uiconfig().end((err, res) => {
                    if (!err && res) {
                        resolve(res);
                    }
                })
            }).then((resolve) => {
                let AUTHORIZE_ENDPOINT = resolve.body.identityServerUrl + "oauth2/authorize";
                let TOKEN_ENDPOINT = resolve.body.identityServerUrl + "oauth2/token";
                let RESPONSE_TYPE = "code";
                let SCOPE = "openid";
                let GRANT_TYPE = "authorization_code";
                let CLIENT_URL = resolve.body.clientURL;
                let REDIRECT_URI = resolve.body.redirectURI;
        
                let cipherClientId = resolve.body.clientKey;
                let bytes  = CryptoJS.AES.decrypt(cipherClientId, 'Secret');
                let CLIENT_ID = bytes.toString(CryptoJS.enc.Utf8);
        
                let cipherSecret = resolve.body.clientSecret;
                let bytes1  = CryptoJS.AES.decrypt(cipherSecret, 'Secret');
                let CLIENT_SECRET = bytes1.toString(CryptoJS.enc.Utf8);
                
                const body = [];
                body.push(`client_id=${ CLIENT_ID }`);
                body.push(`client_secret=${ CLIENT_SECRET }`);
                body.push(`code=${ code }`);
                body.push(`grant_type=${ GRANT_TYPE }`);
                body.push(`redirect_uri=${ REDIRECT_URI }`);
                
                sendTokenRequest(body, TOKEN_ENDPOINT, CLIENT_URL)
                .then(response => {
                    //console.log("TOKEN REQUEST SUCCESS", response);
                    UserDetails.OAuthJWT = response[1].access_token;
                    UserDetails.Authentication = "Bearer " + response[1].access_token;
                    UserDetails.bpId = response[1].BPID;
                    UserDetails.userId = response[1].sub;
                    UserDetails.userName = response[1].given_name;
                    UserDetails.userRole = response[1].groups;

                    //forward to user page.
                    //this.props.history.push( '/dashboard' );
                    window.location.href = window.location.protocol + '//' + window.location.host + "/dashboard";

                    this.setState({
                        tokenResponse: response[0],
                        idToken: response[1],
                        isLoggedIn: true
                    })
                })
                .catch((error => {
                    //console.log("TOKEN REQUEST ERROR", error);
                    this.setState({ isLoggedIn: false });
                }));
            }).then((reject) => {
            }).finally(()=>{
            })
            
        }
        else {
            window.location.href = window.location.protocol + '//' + window.location.host;
        }
    }

    /**
     * Handles login button click
     */
    handleLoginBtnClick = () => {
        sendAuthorizationRequest();
    };

    /**
     * Handles logout button click
     */
    handleLogoutBtnClick = () => {
        dispatchLogout();
    };

    render() {
        //console.log(this.context)
        const { tokenResponse, idToken, isLoggedIn } = this.state;
        return (
            <Grid>
                <Grid.Column verticalAlign='middle'>
                    <Segment loading padded basic style={{ minHeight: '36vh' }}>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

export default HomeComponent;