import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Header, Grid, Divider, Icon, Card, List, Image, Menu, Segment, Sidebar, Button, Label } from "semantic-ui-react";
import "../css/dashboard.css";
import utility from '../../commons/utility.js'
import { withTranslation } from "react-i18next";
import PageHeader from "../PageHeader.jsx";
import ajax from '../../commons/agent.js'


class PlatformAdminDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                "totalProductActive": 0,
                "totalWantActive": 0,
                "totalMyProductActive": 0,
                "totalMyProducts": 0,
                "totalMyProductInactive": 0,
                "totalMyProductDraft": 0,
                "totalMyWants": 0,
                "totalMyWantActive": 0,
                "totalMyWantInactive": 0,
                "totalMyWantDraft": 0,
                "totalActiveContracts": 0,
                "totalContractsPendingWithMe": 0,

            }
        }
    }
    // componentDidMount() {
    //     setTimeout(this.onLoad, 500)
    // }

    // onLoad = () => {
    //     ajax.DataProduct.fetchDashboardCounts().end((err, response) => {
    //         if (!err && response) {
    //             this.setState({ data: response.body });
    //         }
    //     })

    // }

    render() {
        const { t } = this.props
        const { data } = this.state
        return (
            <>
                <PageHeader />

                

            </>
        );
    }
}

export default withTranslation()(PlatformAdminDashboard)
